import Loading from "@core/ui/Loading";
import MessagingSpotlightButton from "@features/clinician-dashboard/components/MessagingSpotlightButton";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ArrowRightAltOutlined,
  Bookmark,
  BookmarkBorderOutlined,
  Check,
  ChecklistOutlined,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { add, format, isValid, parseISO } from "date-fns";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import useClinicianTasks, {
  Task,
  TaskGroup,
  TaskType,
} from "../hooks/useClinicianTasks";
import useResolveCustomTask from "../hooks/useResolveCustomTask";
import useResolveTask from "../hooks/useResolveTask";
import useSnooze from "../hooks/useSnooze";
import useUnsnooze from "../hooks/useUnsnooze";
import AddTaskDialog from "./AddTaskDialog";
import EditCustomTaskDialog from "./EditTaskDialog";

const schema = yup.object({
  dt: yup.string().required("Required"),
});

const SnoozeTaskCustomTimeDialog = ({
  isOpen,
  onClose,
  onSuccess,
  task,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  task: Task;
}) => {
  const formId = `snooze-task-custom-time-form:${task.id}`;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      dt: add(new Date(), {
        hours: 3,
      }).toISOString(),
    },
    resolver: yupResolver(schema),
  });

  const { mutateAsync: snooze } = useSnooze();

  const onSubmit = handleSubmit(
    (values) => {
      return toast
        .promise(
          snooze({
            id: task.id,
            clinician_email: task.clinician_email,
            type: task.type,
            remind_at: values.dt,
          }),
          {
            loading: "Snoozing...",
            success: "Task snoozed!",
            error: "Failed to snooze task",
          }
        )
        .then(onClose)
        .then(onSuccess);
    },
    (err) => console.error(err)
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Snooze task</DialogTitle>

      <DialogContent>
        <Box
          mt={1}
          component="form"
          id={formId}
          onSubmit={(e) => {
            e.stopPropagation();
            return onSubmit(e);
          }}
        >
          <Controller
            control={control}
            name="dt"
            render={({ field, fieldState }) => (
              <DateTimePicker
                label="Remind me at"
                value={parseISO(field.value)}
                onChange={(e) => {
                  if (isValid(e)) {
                    return field.onChange(e.toISOString());
                  }
                  return field.onChange(undefined);
                }}
                minDateTime={new Date()}
                slotProps={{
                  textField: {
                    error: Boolean(fieldState?.error?.message),
                    fullWidth: true,
                    helperText: fieldState?.error?.message,
                  },
                }}
              />
            )}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" form={formId} disabled={isSubmitting}>
          Snooze
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getSnoozeSuggestions = () => [
  {
    label: "In 1 hour",
    value: add(new Date(), {
      hours: 1,
    }),
  },
  {
    label: "In 3 hours",
    value: add(new Date(), {
      hours: 3,
    }),
  },
  {
    label: "Tomorrow",
    value: add(new Date(), {
      days: 1,
    }),
  },
  {
    label: "Next week",
    value: add(new Date(), {
      days: 7,
    }),
  },
];

const SnoozeTaskButton = ({ task }: { task: Task }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);
  const menuId = `${task.type}:${task.id}`;

  const { mutateAsync: snooze } = useSnooze();
  const { mutateAsync: unsnooze } = useUnsnooze();

  const [isChoosingCustomTime, setIsChoosingCustomTime] = useState(false);

  if (task.is_snoozed) {
    return (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          return unsnooze({
            id: task.id,
            clinician_email: task.clinician_email,
          });
        }}
      >
        <Bookmark color="primary" />
      </IconButton>
    );
  }

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
        aria-controls={isOpen ? menuId : undefined}
        aria-haspopup="true"
      >
        <BookmarkBorderOutlined />
      </IconButton>

      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: 350,
          },
        }}
      >
        <ListItem>
          <Typography variant="body2">Remind me...</Typography>
        </ListItem>

        {getSnoozeSuggestions().map((suggestion) => (
          <MenuItem
            onClick={() =>
              snooze({
                id: task.id,
                clinician_email: task.clinician_email,
                type: task.type,
                remind_at: suggestion.value.toISOString(),
              }).then(handleClose)
            }
            key={suggestion.label}
          >
            <ListItemText>{suggestion.label}</ListItemText>
            <Typography variant="body2" color="text.secondary">
              {format(suggestion.value, "EEE, h:mm a")}
            </Typography>
          </MenuItem>
        ))}

        <Divider />

        <MenuItem onClick={() => setIsChoosingCustomTime(true)}>
          Pick date & time
        </MenuItem>
      </Menu>

      {isChoosingCustomTime ? (
        <SnoozeTaskCustomTimeDialog
          isOpen
          onClose={() => setIsChoosingCustomTime(false)}
          onSuccess={handleClose}
          task={task}
        />
      ) : null}
    </>
  );
};

const CompleteCustomTaskButton = ({ task }: { task: Task }) => {
  const { mutateAsync: complete } = useResolveCustomTask();
  const handleCompleteTask = async () => {
    await toast.promise(
      complete({
        id: task.id,
        clinician_email: task.clinician_email,
      }),
      {
        loading: "Completing task...",
        success: "Task completed successfully!",
        error: "Failed to complete the task.",
      }
    );
  };
  return (
    <>
      <Tooltip title="Mark as complete" placement="top">
        <IconButton onClick={handleCompleteTask}>
          <Check />
        </IconButton>
      </Tooltip>
    </>
  );
};

const ResolveTaskButton = ({ task }: { task: Task }) => {
  const { mutateAsync: resolve } = useResolveTask();
  const handleCompleteTask = async () => {
    await toast.promise(
      resolve({
        resolvable_task_id: task.resolvable_task_id,
        clinician_email: task.clinician_email,
        type: task.type,
      }),
      {
        loading: "Completing task...",
        success: "Task completed successfully!",
        error: "Failed to complete the task.",
      }
    );
  };
  return (
    <>
      <Tooltip title="Mark as complete" placement="top">
        <IconButton onClick={handleCompleteTask}>
          <Check />
        </IconButton>
      </Tooltip>
    </>
  );
};

const TASK_GROUP_TABLE_PAGE_SIZE_OPTIONS = [5, 10, 50];
const TASK_GROUP_TABLE_INITIAL_STATE = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
  pagination: {
    paginationModel: { pageSize: TASK_GROUP_TABLE_PAGE_SIZE_OPTIONS[0] },
  },
};

const TaskGroupAccordion = ({
  taskGroup,
  onEditClick,
}: {
  taskGroup: TaskGroup;
  onEditClick: (task: Task) => void;
}) => {
  if (taskGroup.count === 0) {
    return null;
  }

  const TASK_GROUP_TABLE_COLUMNS: GridColDef<Task>[] = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "_actions",
      headerName: "Actions",
      renderCell: (params) => {
        if (params.row.link_url.startsWith("messaging_spotlight")) {
          return (
            <Stack direction="row">
              <ResolveTaskButton task={params.row} />

              <MessagingSpotlightButton task={params.row} />
            </Stack>
          );
        }
        if (
          [TaskType.SCHEDULE_MEMBER_TO_THERAPIST_ASSIGNED_FREQUENCY].includes(
            params.row.type
          )
        ) {
          return (
            <Stack direction="row">
              <SnoozeTaskButton task={params.row} />
              <Tooltip title={params.row.link_text}>
                <IconButton
                  component="a"
                  href={params.row.link_url}
                  target="_blank"
                >
                  <ArrowRightAltOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
        if ([TaskType.CUSTOM_TASK].includes(params.row.type)) {
          return (
            <Stack direction="row">
              <IconButton onClick={() => onEditClick(params.row)}>
                <EditIcon />
              </IconButton>
              <CompleteCustomTaskButton task={params.row} />
            </Stack>
          );
        }

        if (
          params.row.type === TaskType.DISCHARGE_NOTE_NEEDED ||
          params.row.type === TaskType.CASELOAD_REVIEW
        ) {
          return (
            <Stack direction="row">
              <Tooltip title={params.row.link_text}>
                <IconButton
                  component="a"
                  href={params.row.link_url}
                  target="_blank"
                >
                  <ArrowRightAltOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }

        return (
          <Stack direction="row">
            <ResolveTaskButton task={params.row} />

            <Tooltip title={params.row.link_text}>
              <IconButton
                component="a"
                href={params.row.link_url}
                target="_blank"
              >
                <ArrowRightAltOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  // TODO: the row variant for single tasks is bugged and closing itself on
  // any click event. it can be re-enabled when this is resolved.
  // if (
  //   taskGroup.count === 1 &&
  //   [
  //     TaskType.SEND_PERSONALIZED_WELCOME_MESSAGE,
  //     TaskType.FOLLOW_UP_AFTER_FIRST_SESSION,
  //     TaskType.FOLLOW_UP_MESSAGE_FOR_CANCELLED_SESSION,
  //     TaskType.FOLLOW_UP_MESSAGE_TWO_DAYS_SINCE_LAST_SESSION_NO_FUTURE_SESSIONS,
  //   ].includes(taskGroup.type as TaskType)
  // ) {
  //   return (
  //     <MessagingSpotlightButton
  //       task={taskGroup.tasks[0]}
  //       title={taskGroup.title}
  //       variant="row"
  //     />
  //   );
  // }

  return (
    <Accordion
      // disableGutters
      sx={
        {
          // borderTop: "none",
          // borderRight: "none",
          // borderLeft: "none",
          // // border: "1px solid #E0E0E0",
        }
      }
    >
      <AccordionSummary>
        <Box display="flex" alignItems="center" height={2} gap={0.5}>
          <Typography fontWeight={500}>{taskGroup.title}</Typography>

          <Chip
            color="warning"
            size="small"
            label={taskGroup.count}
            sx={{ ml: 1 }}
          />

          {taskGroup.title_url ? (
            <Tooltip title="See all Members scheduling adherence">
              <IconButton
                component="a"
                href={taskGroup.title_url}
                target="_blank"
              >
                <ArrowRightAltOutlined />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <DataGridPro
          sx={{
            border: "none",
          }}
          density="compact"
          disableRowSelectionOnClick
          columns={TASK_GROUP_TABLE_COLUMNS}
          rows={taskGroup.tasks}
          initialState={TASK_GROUP_TABLE_INITIAL_STATE}
          pageSizeOptions={TASK_GROUP_TABLE_PAGE_SIZE_OPTIONS}
          pagination
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default function ClinicianTasks({
  clinicianEmail,
  includeSnoozed = false,
}: {
  clinicianEmail: string;
  includeSnoozed?: boolean;
}) {
  const { data, isLoading, error } = useClinicianTasks(clinicianEmail, {
    select: useCallback(
      (d) => {
        if (includeSnoozed) {
          return d;
        }

        const filteredGroups = d.task_groups.map((group) => {
          const withoutSnoozedAndResolved = group.tasks.filter((task) => {
            return !task.is_snoozed && !task.is_resolved;
          });

          return {
            ...group,
            tasks: withoutSnoozedAndResolved,
            count: withoutSnoozedAndResolved.length,
          };
        });

        return {
          ...d,
          task_groups: filteredGroups,
        };
      },
      [includeSnoozed]
    ),
    staleTime: 5 * 60 * 1000, // 5 mins
  });

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState<Task | null>(null);
  const handleEditClick = (task: Task) => {
    setCurrentTask(task);
    setIsEditDialogOpen(true);
  };

  return (
    <>
      <Box
        component={Paper}
        p={3}
        height="100%"
        sx={{
          paddingBottom: 0,
          border: "1px solid #CBD5E1",
          borderRadius: "8px",
        }}
        minHeight={381}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          mb={2}
        >
          <Stack direction="row" spacing={1}>
            <ChecklistOutlined sx={{ color: "#ABAEB4" }} />
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: 700,
              }}
            >
              To do
            </Typography>
          </Stack>
          <Button
            endIcon={<AddIcon />}
            onClick={() => {
              setIsAddDialogOpen(true);
            }}
          >
            ADD
          </Button>
        </Stack>

        <AddTaskDialog
          open={isAddDialogOpen}
          onClose={() => {
            setIsAddDialogOpen(false);
          }}
          clinicianEmail={clinicianEmail}
        />
        <EditCustomTaskDialog
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          task={currentTask}
        />

        {
          // eslint-disable-next-line no-nested-ternary
          isLoading ? (
            <Loading />
          ) : // eslint-disable-next-line no-nested-ternary
          error ? (
            <Typography color="error">Failed to load tasks</Typography>
          ) : isEmpty(data?.task_groups) ? (
            <Typography color="textSecondary">No tasks to show yet</Typography>
          ) : (
            <Box
              sx={{
                pb: 2,
              }}
            >
              {data.task_groups.map((taskGroup) => (
                <TaskGroupAccordion
                  key={taskGroup.title}
                  taskGroup={taskGroup}
                  onEditClick={handleEditClick}
                />
              ))}
            </Box>
          )
        }
      </Box>
    </>
  );
}
